// Make requests to CryptoCompare API
export async function makeApiRequest(path, options = null) {
	try {
		let url = path;
		if (!url.startsWith('http')) {
			url = `https://api.meo.tools${path}`;
			// url = `https://api2.poocoin.app${path}`;
		}
		const response = await fetch(url, options);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// Make requests to CryptoCompare API
export async function makeApiRequestToken(path, options = null) {
	try {
		let url = path;
		if (!url.startsWith('http')) {
			url = `https://api1.poocoin.app${path}`;
		}
		const response = await fetch(url, options);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}
