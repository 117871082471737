export const LANGUAGES = {
  en: {
    Connect: "Connect",
    Logout: "Logout",
    PriceChartEthereumBSCPolygon: "Price chart (Ethereum / BSC / Polygon)",
    EnterATokenNameAddressEthereumBSCOrPolygon: "Enter a token name / address (Ethereum, BSC or Polygon)",
    ClearSearchHistory: "Clear search history",
    TopTokensBy24hVolume: "Top tokens by 24h volume",
    Token: "Token",
    Network: "Network",
    Volume24h: "Volume, 24h",
    Liquidity: "Liquidity",
    Price: "Price",
    Watchlist: "Watchlist",
    Wallet: "Wallet",
    History: "History",
    MyWatchlistPortfolio: "My Watchlist - Portfolio",
    Alert: "Alert",
    Balance: "Balance",
    Address: "Address",
    Control: "Control",
    CopyAddress: "Copy address",
    GoToBscscan: "Go to bscscan",
    ViewChart: "View chart",
    AddYourBalance: "Add your balance",
    DeleteThisToken: "Delete this token",
    YourBalanceHere: "Your balance here",
    Alerts: "Alerts",
    PressTheButtonBelowToGetStartedAddingAlerts: "Press the button below to get started adding alerts.",
    AddAlert: "Add alert",
    CreateAlert: "Create alert",
    PriceRisesAbove: "Price rises above",
    PriceDropsTo: "Price drops to",
    AlertType: "Alert Type",
    Value: "Value",
    CurrentPrice: "Current price",
    Cancel: "Cancel",
    UpdateThisAlert: "Update this alert",
    DeleteThisAlert: "Delete this alert",
    TotalBalance: "Total Balance",
    Configuration: "Configuration",
    AlertViaTelegram: "Alert via Telegram",
    Connected: "Connected",
    OpenTheMeoToolsBOT: "Open the Meo.tools BOT",
    StartBot: "Start bot",
    ConnectToTheMeoToolsBOT: "Connect to the Meo.tools BOT",
    SendATestMessage: "Send a test message",
    AllStepsCompletedYouReFinished: "All steps completed - you're finished",
    Reset: "Reset",
    WelcomeTo: "Welcome to",
    TheUltimateCombinationBetweenLong: "The ultimate combination between MetaMask, PooCoin, PancakeSwap, and Alert tool to only one platform including desktop, mobile, and app version.",
    Features: "Features",
    TrackYourTokenWatchlistPortfolioInRealTime: "Track your token Watchlist - Portfolio in real-time.",
    NeverMissAnOpportunityWithTokenAlerting: "Never miss an opportunity with token alerting.",
    AMaximumOf10TokensCanBeCreatedForYourWatchlistPortfolio: "A maximum of 10 tokens can be created for your Watchlist - Portfolio",
    AMaximumOf5AlertsCanBeCreatedForAToken: "A maximum of 5 alerts can be created for a token.",
    AMaximumOf20AlertsCanBeCreatedForAllTokens: "A maximum of 20 alerts can be created for all tokens.",
    AKindlyNoticeThatTheCurrentVersionLong: "A kindly notice that the current version has just supported alerting when you open the browser (may on a tab). Please see more at",
    Copyright: "Copyright",
    Version: "Version",
  },
  id: {
    Connect: "HUBUNGKAN",
    Logout: "Keluar",
    PriceChartEthereumBSCPolygon: "Grafik Harga (Ethereum / BSC / Polygon)",
    EnterATokenNameAddressEthereumBSCOrPolygon: "Masukan Sebuah Nama Token / Alamat (Ethereum, BSC atau Polygon)",
    ClearSearchHistory: "Bersihkan Riwayat Penelusuran",
    TopTokensBy24hVolume: "Token teratas berdasarkan volume 24 jam",
    Token: "Token",
    Network: "Jaringan",
    Volume24h: "Volume, 24 jam",
    Liquidity: "Liquiditas",
    Price: "Harga",
    Watchlist: "Daftar pantauan",
    Wallet: "Dompet",
    History: "Riwayat",
    MyWatchlistPortfolio: "Daftar pantauan Saya  - Portofolio",
    Alert: "Peringatan",
    Balance: "Saldo",
    Address: "Alamat",
    Control: "Kontrol",
    CopyAddress: "Salin Alamat",
    GoToBscscan: "Pergi ke bscscan",
    ViewChart: "Tampilkan grafik",
    AddYourBalance: "Tambahkan saldo anda",
    DeleteThisToken: "Hapus token ini",
    YourBalanceHere: "Saldo anda disini",
    Alerts: "Peringatan",
    PressTheButtonBelowToGetStartedAddingAlerts: "Tekan tombol dibawah ini untuk mendapatkan peringatan",
    AddAlert: "Tambahkan peringatan",
    CreateAlert: "Buat peringatan",
    PriceRisesAbove: "Harga naik diatas",
    PriceDropsTo: "Harga turun ke",
    AlertType: "Jenis peringatan",
    Value: "Nilai",
    CurrentPrice: "Harga saat ini",
    Cancel: "Batal",
    UpdateThisAlert: "Perbarui peringatan",
    DeleteThisAlert: "Hapus peringatan ini",
    TotalBalance: "Total saldo",
    Configuration: "Pengaturan",
    AlertViaTelegram: "Peringatan melalui telegram",
    Connected: "Terhubung",
    OpenTheMeoToolsBOT: "Buka Meo.tools BOT",
    StartBot: "Mulai bot",
    ConnectToTheMeoToolsBOT: "Hubungkan ke Meo.tools BOT",
    SendATestMessage: "Kirim sebuah pesan teks",
    AllStepsCompletedYouReFinished: "Semua langkah telah lengkap - anda menyelesaikanya",
    Reset: "Ulang",
    WelcomeTo: "Selamat datang di",
    TheUltimateCombinationBetweenLong: "Kombinasi andalan antara MetaMask, PooCoin, PancakeSwap, dan alat peringatan dalam satu platform termasuk komputer, seluler dan versi aplikasi.",
    Features: "Fitur",
    TrackYourTokenWatchlistPortfolioInRealTime: "Lacak token anda di daftar pantauan",
    NeverMissAnOpportunityWithTokenAlerting: "Tidak melewatkan sebuah peluang dengan fitur peringatan token",
    AMaximumOf10TokensCanBeCreatedForYourWatchlistPortfolio: "Maksimal 10 token yang dapat anda buat di daftar pantauan - portofolio",
    AMaximumOf5AlertsCanBeCreatedForAToken: "Maksimal 5 peringatan yang dapat anda buat  untuk sebuah token",
    AMaximumOf20AlertsCanBeCreatedForAllTokens: "Maksimal 20 peringatan yang dapat anda buat untuk semua token",
    AKindlyNoticeThatTheCurrentVersionLong: "Perlu diperhatikan bahwa pada versi saat ini fitur peringatan akan bekerja ketika anda membuka peramban. lihat lebih lanjut di",
    Copyright: "Hak Cipta",
    Version: "Versi",
  },
  cn: {
    Connect: "连接",
    Logout: "登出",
    PriceChartEthereumBSCPolygon: "K线 (Ethereum / BSC / Polygon)",
    EnterATokenNameAddressEthereumBSCOrPolygon: "输入币种名称/合约地址(Ethereum, BSC or Polygon)",
    ClearSearchHistory: "清楚搜索历史记录",
    TopTokensBy24hVolume: "24交易量热门币种",
    Token: "币种",
    Network: "网络",
    Volume24h: "交易量，24小时",
    Liquidity: "流动性",
    Price: "价格",
    Watchlist: "关注清单",
    Wallet: "钱包",
    History: "历史",
    MyWatchlistPortfolio: "我的观察清单",
    Alert: "提醒",
    Balance: "余额",
    Address: "合约地址",
    Control: "控制",
    CopyAddress: "复制合约地址",
    GoToBscscan: "去bscscan",
    ViewChart: "看K线",
    AddYourBalance: "增加您的余额",
    DeleteThisToken: "删除此币种",
    YourBalanceHere: "这里您的余额",
    Alerts: "提醒",
    PressTheButtonBelowToGetStartedAddingAlerts: "点击下方按钮来增加提醒",
    AddAlert: "增加提醒",
    CreateAlert: "创造提醒",
    PriceRisesAbove: "价格升高到",
    PriceDropsTo: "价格降低到",
    AlertType: "提醒类型",
    Value: "值",
    CurrentPrice: "现在价格",
    Cancel: "取消",
    UpdateThisAlert: "更新此提醒",
    DeleteThisAlert: "删除此提醒",
    TotalBalance: "所有余额",
    Configuration: "配置",
    AlertViaTelegram: "通过Telegram提醒",
    Connected: "已连接",
    OpenTheMeoToolsBOT: "打开Meo.tools机器人",
    StartBot: "启动机器人",
    ConnectToTheMeoToolsBOT: "连接到Meo.tools机器人",
    SendATestMessage: "发送测试信息",
    AllStepsCompletedYouReFinished: "所有步骤完成-您已设置结束",
    Reset: "重置",
    WelcomeTo: "欢迎使用",
    TheUltimateCombinationBetweenLong: "此应用平台整合MetaMask, PooCoin, PancakeSwap以及提醒工具为一体，含电脑，手机以及app版本。",
    Features: "特点",
    TrackYourTokenWatchlistPortfolioInRealTime: "实时追踪您的币种观察清单",
    NeverMissAnOpportunityWithTokenAlerting: "提醒功能会帮助您抓住每个机会",
    AMaximumOf10TokensCanBeCreatedForYourWatchlistPortfolio: "观察清单最多可含10个币种",
    AMaximumOf5AlertsCanBeCreatedForAToken: "每个币种最多可含5个提醒",
    AMaximumOf20AlertsCanBeCreatedForAllTokens: "所有的币种组多可含20个提醒",
    AKindlyNoticeThatTheCurrentVersionLong: "目前版本只支持浏览器（也可能标签页）模式下的提醒。更多信息请看",
    Copyright: "版权",
    Version: "版本",
  }
}